@import '~styles/mixins';
@import '~styles/variables';
.spinLoading{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.4);
    z-index: 2000;
    top:0;
    left:0
}