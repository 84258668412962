@import '~styles/variables';
@import '~styles/mixins';
.requiredVisaSelectPopupStyle{
    padding: 16px 8px;
    border-radius: 8px;
    box-shadow: 5px 5px 50px 0px rgba(26, 32, 44, 0.06);
    &.serviceFor{
        .ant-select-item-option{
            padding-left: 32px;
        }
    }
    .ant-select-item-option{
        padding: 16px;
        border-radius: 4px;
        @include font-style(16px,500,#223C5F,$lineHeight:150%);
        letter-spacing: 0.2px;
    }
    .ant-select-item-option-active{
        background: #F9FAFB;
    }
    .ant-select-item-option-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cannotmoreManager{
            @include font-style(14px,400,rgba(34, 60, 95, 0.40));
        }
    }
    .ant-select-item-option-disabled{
        color: rgba(34, 60, 95, 0.40);
    }
}

.constCalculaterSelectPopupStyle{
    // padding: 16px 8px;
    // border-radius: 8px;
    // box-shadow: 5px 5px 50px 0px rgba(26, 32, 44, 0.06);
    // .ant-select-item-option{
    //     padding: 16px;
    //     border-radius: 4px;
    //     @include font-style(16px,500,#223C5F,$lineHeight:150%);
    //     letter-spacing: 0.2px;
    // }
    // .ant-select-item-option{
    //   height: 40px;
    // }
    .ant-select-item-option-active{
        background-color: #FAFAFA;
    }
    .ant-select-item-option-selected{
        background: $th-new-black4-sub!important;
        .ant-select-item-option-content{
            color:#fff
        }
    }
    // .ant-select-item-option-content{
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     .cannotmoreManager{
    //         @include font-style(14px,400,rgba(34, 60, 95, 0.40));
    //     }
    // }
    // .ant-select-item-option-disabled{
    //     color: rgba(34, 60, 95, 0.40);
    // }
}


