$body-bg: #dee9f3;
$color-white: #ffffff;
$cyan-blue: #e8eff8;
$cyan-blue-second: #f6f9fd;
$place-holder-c: #aaaaaa;
$dark-black: #253544;
$text-lable: #231f20;
$black-c: #000000;
$button-border: #3a3a3a;
$link-color: #383838;
$theme-orange: #f37426;
$theme-grey:  #223C5F;

$light-theme-orange: #eba171;
$menu-circle-c: #f1ded1;
$btn-bg-color: #e47a3c;
$solitude: #ebf1f8;
$common-border-button: #d1dfee;
$label-color: #6c757d;
$placeholder: #a6abb1;
$green-color: #14a44d;
$text-green: #57be80;
$color-dark-grey: #979595;
$application-expand: #949aa0;
$btn-diff-color: #e4a11b;
$box-shodow: rgba(0, 0, 0, 0.06);

$th-new-black1: #111822;
$th-new-black2: #1D2D3D;
$th-new-black3: #223B55;
$th-new-black3-main: #223B55;
$th-new-black4: #8796AA;
$th-new-black4-sub: #8796AA;
$th-new-black5: #3F5D83;
$th-new-black6: #334D6F;
$th-new-black7: #D5DBE9;
$th-new-black-linear: #linear-gradient(90deg, #D3D6DC 0%, #8190A2 100%);
$th-new-black8-bg: #eaedf4;
