@import '~styles/mixins';
@import '~styles/variables';
.navWarp{
  background-color: rgba(255,255,255,1);
  padding:1rem!important ;
  &.homeBgTransparent{
    background-color: transparent;
  }
  max-height: 100vh;
  overflow-y: auto;
}
.navContainer{
  max-width: $centerContentWidth!important;
}
.navigation{
    color: #000!important;
    font-size: 1.1rem!important;
    font-weight: 500!important;
    line-height: 130%!important;
    font-family: Roboto, sans-serif!important;
    text-transform: none!important;
    &.white{
      color:$white!important;
      svg path{
        fill: $white!important;
      }
    }
    &:hover {
      color: $E7!important;
      svg path{
        fill:$E7!important;
      }
    }
}
.menuBtn{
  border-color: $E7!important;
  .menuIcon{
    color: $E7!important;
  }
}

.navigationContainer{
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
}
.navigationWarp{
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
}
.fullLogo{
  width: 130px;
}
.navRight{
  display: flex;
  align-items: center;
}
.classDisplay {
  display: flex;
  &.mobileContactBtn{
    margin-right: 6px;
  }
}
.contactBtn {
    display: flex;
    border-radius: 100px !important;
    padding: 18px 40px !important;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 100% !important;
    color: $white;
    text-align: center;
    text-decoration: none;
    background-color: $E7;
    &:hover {
      color: $white;
        background-color:color-mix(in srgb, $E7 , black 20%);
    }
    @media (max-width: 400px) {
      padding: 14px 20px !important;
      font-size: 12px !important;

    }
}
.navItem {
    font-size: 18px;
    margin: 0 10px; // Add margin to each navbar item
    &:hover {
      .navigation{
        color: $E7!important;
      }
    }
}

.popoverContainer {
 
  @media (max-width: 992px) {
    position: relative;
    width: 100%;
    background-color: unset;
    border:none;
    padding: 0;
    box-shadow:none;
  }
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 8px;
  flex-flow: column;
  padding: 20px 29px;
  display: flex;
  width: 750px;
  box-shadow: 3px 3px 20px rgba(33, 41, 77, .15);

  .row {
    min-width: 350px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    color: #223c5f;
    text-align: left;
    border-radius: 12px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    overflow: visible;
    flex-direction: row;
    @media (max-width: 767px) {
      width: 100%;
      padding:10px 0px;
      flex-direction: column;
      min-width: auto;
    }

    .cell {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      color: #223b55;
      background-color: #eef2f6;
      transition-property: all;
      border-radius: 12px;
      width: 50%;
      padding: 15px;
      height: 90px;
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        width: 100%;
        height: auto;
        padding:15px 2px;
      }
      &:hover {
        color: #fff;
        background-color: #223b55;
        // svg {
        //   fill: white;
        //   stroke: white;
        // }
      }
    }

    .leftIcon {
      height: 2rem;
      svg{
        height: 100%;
      }
    }

    .rightText {
      font-weight: 600;
    }
  }

  .bottomRow {
    color: #fff;
    background-color: #1d3557;
    text-decoration: none;
    padding: 20px;

    &:hover {
      color: #fff !important;
      background-color: rgba(34, 59, 85, .8) !important;
    }

    width: 100%;
    display: flex;
    justify-content: space-evenly;

  }
}
.popoverDrop{
    z-index: 1800;
    :global{
        .ant-popover-inner{
            border-radius: 8px;
        }
        .ant-popover-inner-content{
            padding: 0;
        }
        .ant-popover-arrow{
          display: none;
        }
    }
}
.serviceNav{
  display: flex;
  align-items: center;
}
