@import '~styles/mixins';
@import '~styles/variables';
$--header-height: 140px;
$--safe-margin: 2rem;
$--text-align: initial;
$--padding-top: 96px;
$--padding-bottom: 96px;
$--padding-40: 40px;

.footer {
    width: 100%;
    background-color: $--e-global-color-b95dfff;
    $--widgets-spacing: 16px 16px;

    .container {
        $--container-max-width: 1200px;
        $--container-default-padding-top: 0px;
        $--container-default-padding-right: 0px;
        $--container-default-padding-bottom: 0px;
        $--container-default-padding-left: 0px;
        $--border-radius: 0;
        $--border-top-width: 0px;
        $--border-right-width: 0px;
        $--border-bottom-width: 0px;
        $--border-left-width: 0px;
        $--border-style: initial;
        $--border-color: initial;
        $--container-widget-width: 100%;
        $--container-widget-height: initial;
        $--container-widget-flex-grow: 0;
        $--container-widget-align-self: initial;
        $--content-width: min(100%, 1200px, 1140px);
        $--width: 100%;
        $--min-height: initial;
        $--height: auto;
        $--text-align: initial;
        $--margin-top: 0px;
        $--margin-right: 0px;
        $--margin-bottom: 0px;
        $--margin-left: 0px;
        $--padding-top: $--container-default-padding-top, 10px;
        $--padding-right: $--container-default-padding-right, 10px;
        $--padding-bottom: $--container-default-padding-bottom, 10px;
        $--padding-left: $--container-default-padding-left, 10px;
        $--position: relative;
        $--z-index: revert;
        $--overflow: visible;
        $--gap: $--widgets-spacing, 20px;
        $--overlay-mix-blend-mode: initial;
        $--overlay-opacity: 1;
        $--overlay-transition: 0.3s;
        $--e-con-grid-template-columns: repeat(3, 1fr);
        $--e-con-grid-template-rows: repeat(2, 1fr);
        $--margin-block-start: $--margin-top;
        $--margin-block-end: $--margin-bottom;
        $--margin-inline-start: $--margin-left;
        $--margin-inline-end: $--margin-right;
        $--padding-inline-start: $--padding-left;
        $--padding-inline-end: $--padding-right;
        $--padding-block-start: $--padding-top;
        $--padding-block-end: $--padding-bottom;
        $--border-block-start-width: $--border-top-width;
        $--border-block-end-width: $--border-bottom-width;
        $--border-inline-start-width: $--border-left-width;
        $--border-inline-end-width: $--border-right-width;
        position: $--position;
        width: $--width;
        min-width: 0;
        min-height: $--min-height;
        height: $--height;
        border-radius: $--border-radius;
        z-index: $--z-index;
        overflow: $--overflow;
        margin-block-start: $--margin-block-start;
        margin-inline-end: $--margin-inline-end;
        margin-block-end: $--margin-block-end;
        margin-inline-start: $--margin-inline-start;
        padding-inline-start: $--padding-inline-start;
        padding-inline-end: $--padding-inline-end;
        padding-top: 120px;
        padding-bottom: 80px;

        @include maxWidth1025 {
            padding-top: 40px;
            padding-bottom: 60px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .containerMain {
            position: $--position;
            width: $--content-width;
            min-width: 0;
            min-height: $--min-height;
            height: $--height;
            border-radius: $--border-radius;
            z-index: $--z-index;
            overflow: $--overflow;
            margin-block-start: $--margin-block-start;
            margin-inline-end: $--margin-inline-end;
            margin-block-end: $--margin-block-end;
            margin-inline-start: $--margin-inline-start;
            padding-inline-start: $--padding-inline-start;
            padding-inline-end: $--padding-inline-end;
            margin: auto;

            .containerBox {
                position: $--position;
                width: $--width;
                min-width: 0;
                min-height: $--min-height;
                height: $--height;
                border-radius: $--border-radius;
                z-index: $--z-index;
                overflow: $--overflow;
                margin-block-start: $--margin-block-start;
                margin-inline-end: $--margin-inline-end;
                margin-block-end: $--margin-block-end;
                margin-inline-start: $--margin-inline-start;
                padding-inline-start: $--padding-inline-start;
                padding-inline-end: $--padding-inline-end;
                text-align: $--text-align;
                padding-block-start: $--padding-block-start;
                padding-block-end: $--padding-block-end;

                .containerBoxCol {
                    position: $--position;
                    width: $--width;
                    min-width: 0;
                    min-height: $--min-height;
                    height: $--height;
                    border-radius: $--border-radius;
                    z-index: $--z-index;
                    overflow: $--overflow;
                    margin-block-start: $--margin-block-start;
                    margin-inline-end: $--margin-inline-end;
                    margin-block-end: $--margin-block-end;
                    margin-inline-start: $--margin-inline-start;
                    padding-inline-start: $--padding-inline-start;
                    padding-inline-end: $--padding-inline-end;
                }

                h5 {
                    color: #ffffff;
                    font-family: "Poppins", sans-serif;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 36px;
                }

                ul {
                    padding-left: 0px;
                }

                li {
                    min-height: 36px;
                    list-style-type: none;
                    line-height: 25px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @include maxWidth766{
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    a {
                        color: #ffffff;
                        text-decoration: none;
                        font-family: $--e-global-typography-accent-font-family, sans-serif;
                        font-weight: 400;
                        width: 100%;
                        display: flex;
                        font-size: inherit;
                        box-shadow: none;
                        text-decoration: none;
                        box-sizing: border-box;
                        span{
                            color: #ffffff;
                            transition: color .3s;
                            font-family: $--e-global-typography-accent-font-family, sans-serif;
                            font-weight: 100;
                        }
                    }
                    a:hover{
                        color: #cdcdcd;
                        span{
                            color: #cdcdcd;
                        }
                    }

                }
            }
        }
    }
}

.imgCenter {
    height: 63px;
    max-width: 48px;
    margin: auto;

    @include maxWidth1025 {
        margin-left: 0;
    }
}
.containerHr{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    height: 1px;
    background-color: #334155;
    .containerHrMain{
        height: 100%;
        width: 100%;
    }
}
.containerReserved{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 10px;
    position: relative;
    color: #94a3bb;
    font-family: $--e-global-typography-accent-font-family, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 2.5;
}
.reservedLeft{
    text-align: center;
    @include maxWidth766{
        text-align: center;
    }
}
.reservedCenter{
    text-align: center;
}
.reservedRight{
    text-align: right;
    @include maxWidth766{
        text-align: center;
    }
    display: none;
}

.reservedRightSpan{
    width: 35px;
    height: 35px;
    display: inline-block;
    background-color: #cdcdcd;
    border-radius: 17.5px;
    margin-left: 17px;
    text-align: center;
    line-height: 45px;
    a {
        span{
            color: #ffffff;
            transition: color .3s;
            font-family: $--e-global-typography-accent-font-family, sans-serif;
            font-weight: 100;
            font-size: 1.5rem;
        }
    }
    a:hover{
        color: #cdcdcd;
        span{
            color: #e7107e;
        }
    }
}
