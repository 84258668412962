/**
* Variables
*/
$E7:#1095e7;
$white:rgb(255, 255, 255);
$--e-global-color-primary: #1014e7;
$--e-global-color-secondary: #0FA4EA;
$--e-global-color-accent: #F6D200;
$--e-global-color-text: #0F172A;
$--e-global-color-b2894ad: #4A4A68;
$--e-global-color-5e1e919: #1FB6FF;
$--e-global-color-40f63f7: #60CC3D;
$--e-global-color-8ddb30f: #DC2626;
$--e-global-color-b65f2b5: #F0AD4E;
$--e-global-color-15e89b5: #C90060;
$--e-global-color-1e4bfa7: #E7107E;
$--e-global-color-0786997: #FFD8FF;
$--e-global-color-bd085c7: #0086CC;
$--e-global-color-3a00fed: #0FA4EA;
$--e-global-color-ff33f3b: #CFEEFC;
$--e-global-color-9db8eaf: #D6B600;
$--e-global-color-1cf60e0: #F6D200;
$--e-global-color-b797149: #FCF4C5;
$--e-global-color-e3bf43f: #A0A91D;
$--e-global-color-c12a591: #BDC73B;
$--e-global-color-dcd2dd5: #dfd2ef;
$--e-global-color-a76d19c: #0D9488;
$--e-global-color-3fcf820: #14B8A6;
$--e-global-color-7f22e74: #CCFBF1;
$--e-global-color-4762076: #6366F1;
$--e-global-color-c701843: #818CF8;
$--e-global-color-3591aaa: #E0E7FF;
$--e-global-color-3d901b8: #9333EA;
$--e-global-color-a2da29a: #A855F7;
$--e-global-color-5c0a3b7: #F3E8FF;
$--e-global-color-10bfb35: #86198F;
$--e-global-color-ca369eb: #A21CAF;
$--e-global-color-80558fc: #FAE8FF;
$--e-global-color-26207f0: #B91C1C;
$--e-global-color-9115669: #DC2626;
$--e-global-color-35c990d: #FEE2E2;
$--e-global-color-38c5737: #C2410C;
$--e-global-color-68bcbef: #EA580C;
$--e-global-color-ec7a5b4: #FFEDD5;
$--e-global-color-b7bfb36: #0F172A;
$--e-global-color-64ac4d5: #334155;
$--e-global-color-9acb2f2: #94A3B8;
$--e-global-color-3e2fc0b: #CBD5E1;
$--e-global-color-ca70cde: #F1F5F9;
$--e-global-color-04cd239: #FFFFFF;
$--e-global-color-d7de08a: #F8F9FC;
$--e-global-color-f6f9b04: #F4FAFE;
$--e-global-color-f5a959c: #202730;
$--e-global-color-9e5296c: #1F1C31;
$--e-global-color-b95dfff: #141C25;
$--e-global-color-eb70be1: #FFFFFF;
$--e-global-color-73701e5: #0F172A;
$--e-global-color-c519145: #100727CC;
$--e-global-color-dbd57e0: #1007274D;
$--e-global-typography-primary-font-family: "Poppins";
$--e-global-typography-primary-font-size: 3rem;
$--e-global-typography-primary-font-weight: 700;
$--e-global-typography-primary-line-height: 1.2;
$--e-global-typography-secondary-font-family: "Poppins";
$--e-global-typography-secondary-font-size: 1.875rem;
$--e-global-typography-secondary-font-weight: 700;
$--e-global-typography-secondary-line-height: 1.2;
$--e-global-typography-accent-font-family: "Poppins-Regular";
$--e-global-typography-accent-font-size: 1.5rem;
$--e-global-typography-accent-font-weight: 400;
$--e-global-typography-accent-line-height: 1.5;
$--e-global-typography-text-font-family: "Poppins";
$--e-global-typography-text-font-size: 1rem;
$--e-global-typography-text-font-weight: 400;
$--e-global-typography-text-line-height: 1.5;
$--e-global-typography-0d4921d-font-family: "Poppins";
$--e-global-typography-0d4921d-font-size: 3rem;
$--e-global-typography-0d4921d-font-weight: 700;
$--e-global-typography-0d4921d-line-height: 1.2;
$--e-global-typography-0ff79ee-font-family: "Poppins";
$--e-global-typography-0ff79ee-font-size: 2.25rem;
$--e-global-typography-0ff79ee-font-weight: 700;
$--e-global-typography-0ff79ee-line-height: 1.2;
$--e-global-typography-264022c-font-family: "Poppins";
$--e-global-typography-264022c-font-size: 1.5rem;
$--e-global-typography-264022c-font-weight: 700;
$--e-global-typography-45f602c-font-family: "Poppins";
$--e-global-typography-45f602c-font-size: 1.125rem;
$--e-global-typography-45f602c-font-weight: 400;
$--e-global-typography-45f602c-line-height: 1.5;
$--e-global-typography-bbc6fda-font-family: "Poppins";
$--e-global-typography-bbc6fda-font-weight: 700;
$--e-global-typography-bbc6fda-line-height: 1.5;
$--e-global-typography-9730a4e-font-family: "Poppins";
$--e-global-typography-9730a4e-font-size: 0.875rem;
$--e-global-typography-9730a4e-font-weight: 400;
$--e-global-typography-9730a4e-line-height: 1.5;
$font-family: $--e-global-typography-text-font-family,sans-serif;
$font-size: $--e-global-typography-text-font-size;
$font-weight: $--e-global-typography-text-font-weight;
$line-height: $--e-global-typography-text-line-height;
$background-color: $--e-global-color-04cd239;
$--shadow-xl:0px 15px 75px rgb(0 0 0 / 0.1);
$centerContentWidth:1200px;


