@import "colors";
@import "general-sans.css";
@import 'styles/ant-next';

@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Paytone+One&display=swap");

@font-face {
  font-family: gotham-light;
  src: url(../public/fonts/Gotham-Light.ttf);
}
@font-face {
  font-family: gotham-medium;
  src: url(../public/fonts/Gotham-Medium.otf);
}
@font-face {
  font-family: gotham-bold;
  src: url(../public/fonts/Gotham-Bold.ttf);
}
@font-face {
  font-family: gotham-black;
  src: url(../public/fonts/Gotham-Black.otf);
}

@font-face {
  font-family: coltan-light;
  src: url(../public/fonts/Coltan-Gea-Light.ttf);
}
@font-face {
  font-family: coltan-bold;
  src: url(../public/fonts/Coltan-Gea-Bold.ttf);
}

@font-face {
  font-family: "Coltan-Light";
  src: url(../public/fonts/Coltan-Gea-Light.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Coltan-Bold";
  src: url(../public/fonts/Coltan-Gea-Bold.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url(../public/fonts/Satoshi-Medium.woff2) format("woff2"),
    url(../public/fonts/Satoshi-Medium.woff) format("woff"),
    url(../public/fonts/Satoshi-Medium.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-Bold";
  src: url(../public/fonts/Satoshi-Bold.woff2) format("woff2"),
    url(../public/fonts/Satoshi-Bold.woff) format("woff"),
    url(../public/fonts/Satoshi-Bold.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-Black";
  src: url(../public/fonts/Satoshi-Black.woff2) format("woff2"),
    url(../public/fonts/Satoshi-Black.woff) format("woff"),
    url(../public/fonts/Satoshi-Black.ttf) format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url(../public/fonts/Roboto-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url(../public/fonts/poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../public/fonts/poppins/Poppins-Regular.ttf);
}

body {
  font-size: 14px;
  @include maxWidth1025{
    font-size: 12px;
  }
  @include maxWidth766{
    font-size: 10px;
  }
  @include maxWidth420{
    font-size: 8px;
  }
  font-family: Poppins-Regular,sans-serif!important;
  background: $body-bg;
  &.no-background {
    background: none;
  }
  input[type="password"]::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

a,
a:hover {
  text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $th-new-black3-main;
  -webkit-box-shadow: 0 0 0 1000px $color-white inset;
  transition: background-color 5000s ease-in-out 0s;
}
.form-control {
  color: #f37426 !important;
}
.admin-signup-screen {
     background: $theme-grey !important;
  .admin-sighup-white-text{
    color: rgb(133, 15, 15);
  }
  .login-content-layout {
    display: flex;
    flex-direction: column;
    align-items: center
  }
  .login-bottom-text {
    padding: 8px 10px 8px 15px;
    border: 1px solid;
    border-block-color: $theme-orange;
    margin-top: 18px;
    font-weight: 600;
    border-right: 0px;
    border-left: 0px;
    color: white
  }
  .login-top-right-position {
    display: flex;
    margin-left: auto;
    margin-top: 30px
  }
}
.signup-screen {
  height: 100vh;
  min-height: 700px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  .mouse-hover {
    &:hover {
      cursor: pointer;
    }
  }
  .back-to-login {
    color: $label-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .text-block {
    &.login-p {
      h1 {
        margin-bottom: 32px;
      }
      h4 {
        line-height: 50px;
        color: $cyan-blue;
      }
      .login-right {
        margin-left: 226px;
      }
    }
    width: 100%;
    background: $th-new-black3-main;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
    height: 100%;
    h1 {
      font-size: 60px;
      font-family: "Paytone One", sans-serif;
      color: rgb(82, 148, 20);
      line-height: 64px;
    }
    span {
      color: $theme-orange;
      font-family: coltan-light;
    }
    h4 {
      font-family: coltan-light;
      color: $cyan-blue;
      line-height: 64px;
      margin-bottom: 76px;
    }
  }
  .form-block {
    box-sizing: content-box;
    min-width: 468px;
    background: $body-bg;
    padding: 0 34px;
    display: flex;
    align-items: center;
    .form-layout {
      h4 {
        font-family: "Paytone One", sans-serif;
        line-height: 48px;
        margin-bottom: 15px;
      }

      .password-hit {
        margin-bottom: 20px;
        color: $black-c;
        span {
          font-family: gotham-medium;
          display: flex;
          align-items: center;
          svg {
            display: flex;
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
      .bg-color-none {
        border: 3px solid $button-border;
        filter: drop-shadow(4px 6px 15px $box-shodow);
        border-radius: 6px;
        background-color: transparent;
        color: $link-color;
      }
      .already-have-link {
        font-family: gotham-medium;
        color: $link-color;
        span {
          color: $theme-orange;
        }
        a {
          color: $theme-orange;
        }
      }
    }
  }
  .password-container {
    .form-control-password {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-radius: 6px 0 0 6px !important;
    }
    .input-password-icon {
      background: #ffffff;
      padding: 12px 14px;
      box-shadow: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border: none;
      height: 60px;
      border-radius: 0 6px 6px 0;
    }
    .form-control:not(.search-form) {
      box-shadow: none;
    }
    input {
      margin-bottom: 36px;
      @media (max-width: 767px) {
        max-width: unset;
      }
    }
  }
}
@mixin bg-common($bg-color) {
  background: $bg-color;
  box-shadow: 4px 6px 15px $box-shodow;
  border-radius: 6px;
}

.form-control:not(.search-form) {
  @include bg-common($color-white);
  margin-bottom: 20px;
  height: 60px;
  border: 0px;
  padding: 0px 24px;
  color: $text-lable;
  font-family: gotham-medium;
}
.theme-orange {
  color: $theme-orange;
}
.button-dark {
  @include bg-common($th-new-black3-main);
  height: 60px;
  width: 100%;
  color: $color-white;
  border: 0;
  font-family: gotham-medium;
  margin-bottom: 20px;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    cursor: no-drop;
  }
  &:focus {
    outline: none;
  }
  &.btn-border {
    border: 6px solid $btn-bg-color;
    border-radius: 6px;
    width: 236px;
    margin-right: 20px;
    background: $color-white;
    color: $theme-orange;
    font-family: "Paytone One", sans-serif;
    box-shadow: none;
  }
  &.btn-orange {
    background: $btn-bg-color;
    color: $color-white;
  }
}

.form-control::-webkit-input-placeholder {
  color: $place-holder-c;
  font-family: gotham-medium;
}
.form-control:-ms-input-placeholder {
  color: $place-holder-c;
  font-family: gotham-medium;
}
.form-control::placeholder {
  color: $place-holder-c;
  font-family: gotham-medium;
}
.w-fit-content {
  width: fit-content !important;
}
.mr-13 {
  margin-right: 13px;
}
.mr-24 {
  margin-right: 24px;
}

ul,
li {
  margin: 0;
  padding: 0;
}
span::first-letter {
  text-transform: capitalize;
}
.base-timeline {
  list-style-type: none;
  counter-reset: number; /* number 2021*/
  position: relative;
  display: block;
  z-index: 2;
  width: 92%; /* change or remove*/
  padding: 0 40px 0 62px;
  margin-bottom: 100px;
  &::before {
    content: "";
    width: 92%;
    border-top: 4px solid $black-c;
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: -1;
  }
  .base-timeline__item {
    &:last-child {
      width: 0;
    }
    position: relative;
    display: inline-block;
    width: calc(100% / 3 - 15px); /* change width */

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      counter-increment: number; /* number -1*/
      content: counter(number) "";
      border-radius: 50%;
      width: 91px;
      height: 91px;
      background-color: $color-white;
      color: $black-c;
      font-weight: bold;
      transition: all 0.6s ease-in-out;
      box-sizing: border-box;
      border: 8px solid $theme-orange;
      font-family: "Paytone One", sans-serif;
      font-size: 24px;
    }
    .base-timeline__summary-text {
      position: absolute;
      bottom: -4em;
      left: -48px;
      font-size: 24px;
      width: 202px;
      text-align: center;
      font-family: gotham-medium !important;
    }
  }
}
.capitalize-label {
  text-transform: capitalize;
}
.language-dropdown {
  height: auto !important;
}
.layout-container {
  z-index: 1031;
  position: absolute;
  display: table-cell;
  height: 100%;
  overflow: hidden;
  position: fixed;
  .layoutSidenav {
    &.d-flex {
      flex-wrap: nowrap;
      width: 100%;
      position: fixed;
    }
    .layoutSidenav_nav {
      width: 328px;
      height: 100vh;
      z-index: 1038;
      margin-left: 13px;
      .header-logo {
        margin: 38px 0 32px;
        max-width: 315px;
      }
      .header-sidebar {
        background: $theme-orange;
        border-radius: 36px;
        height: calc(100vh - 155px);
        display: flex;
        flex-direction: column;
        width: 315px;
        transition: all 0.3s ease;
        ul {
          padding: 0 8px 0 19px;
          margin-top: 32px;
          flex-grow: 1;
          overflow-y: auto;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            height: 13px;
            width: 13px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 6px !important;
            background-color: $color-white;
            background-clip: padding-box;
            border: 4px solid rgba(0, 0, 0, 0);
          }
          &::-webkit-scrollbar-corner {
            background: $theme-orange;
            border-radius: 0 0 8px 0 !important;
          }
          &::-webkit-scrollbar-track {
            background: $theme-orange;
            border-radius: 0 0 8px 8px !important;
          }
          li {
            &:last-child {
              margin-bottom: 0;
            }
            font-size: 24px;
            font-family: "Paytone One", sans-serif;
            color: $color-white;
            display: flex;
            align-items: center;
            margin-bottom: 48px;
            span {
              height: 64px;
              width: 64px;
              display: grid;
              place-items: center;
              background: $menu-circle-c;
              border-radius: 50%;
              margin-right: 32px;
            }
          }
        }
      }
      .sidebar-footer {
        .language-dropdown-container {
          #dropdown-autoclose-true {
            box-shadow: none;
            &:focus {
              box-shadow: none;
            }
            background: transparent;
            border: none;
          }
        }
        font-family: "Paytone One", sans-serif;
        color: $color-white;
        flex-shrink: 0;
        margin: 0 0 15px 16px;
        height: 40px;
        span {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
    .layout-side-nav-content {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      flex-grow: 1;
      min-height: calc(100vh - 56px);
      margin-right: 47px;
      width: 100%;
      margin-left: 328px;
      .content-block {
        background: $cyan-blue-second;
        border-radius: 16px;
        margin-left: 45px;
        height: calc(100vh - 180px);
        border-radius: 0;
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          height: 13px;
          width: 13px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 6px !important;
          background-color: rgba(210, 211, 215, 1);
          background-clip: padding-box;
          border: 4px solid rgba(0, 0, 0, 0);
        }
        &::-webkit-scrollbar-corner {
          background: $color-white;
        }
        &::-webkit-scrollbar-track {
          background: $color-white;
          border-radius: 8px !important;
        }
      }
    }
  }
}
.content-block {
  &.dashboard-main-content {
    .d-inline-block {
      display: inline-block;
    }
    .red {
      color: #cd2026;
    }
    .input-error-border,
    .text-question-required,
    .date-required-error,
    .dropdown-required-error,
    .upload-file-error,
    .show-error-field {
      border: 1px #cd2026 solid;
      &.content-box {
        box-sizing: content-box;
      }
    }

    background: $body-bg !important;
    padding: 25px;
    .width-80 {
      width: 80%;
    }
    .width-90 {
      width: 90%;
    }
    .width-95 {
      width: 95%;
    }
    .mb-50 {
      margin-bottom: 50px;
    }
    .d-flex {
      display: flex;
      .flex-direction-column {
        flex-direction: column;
      }
    }
    .text-width {
      min-width: 535px;
      h2 {
        font-family: "Paytone One", sans-serif;
      }
    }
    .form-mb-60 {
      &.block-mb-0 {
        margin-bottom: 0 !important;
      }
      margin-bottom: 60px !important;
      .form-fields {
        margin-bottom: 18px;
        &.form-item {
          .fields-width-320 {
            margin-right: 24px;
            width: 320px !important;
          }
          .fields-width-180 {
            margin-right: 24px;
            width: 180px;
            &.dropdown-type {
              height: 60px;
            }
          }
          .date-picker {
            margin-right: 24px;
          }
        }
        .info-msg-width {
          max-width: 336px;
          margin: 10px 0;

          span {
            font-size: 14px;
            line-height: 17px;
            font-family: gotham-medium;
            color: $black-c;
          }
        }
      }
      .form-title-text {
        font-size: 14px;
        line-height: 17px;
        font-family: gotham-medium;
        color: $black-c;
      }
      .info-msg-width-416 {
        max-width: 416px;
        width: unset !important;
        margin-right: 0 !important;
        margin: 10px 0;

        span {
          font-size: 14px;
          line-height: 17px;
          font-family: gotham-medium;
        }
      }

      .custom-radio-btn {
        .mr-40 {
          margin-right: 40px;
        }
        .single-type-question {
          input[type="radio"],
          input[type="checkbox"] {
            + label {
              width: 260px;
            }
          }
        }
        input[type="radio"],
        input[type="checkbox"] {
          &:checked + label {
            color: $text-lable;
          }
          &:checked + label::after {
            opacity: 1;
            content: url(../src/assets/images/radio-check.svg);
            position: absolute;
            left: 32px;
            transform: translate(-50%, 10%);
          }
          appearance: none;
          + label {
            cursor: pointer;
            position: relative;
            line-height: 20px;
            background: $cyan-blue-second;
            border-radius: 6px;
            width: 205px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 17px;
            font-family: gotham-medium;
            color: $place-holder-c;
            margin-bottom: 0px;
            box-shadow: 4px 6px 15px $box-shodow;
          }
        }
      }

      .company-details {
        .fields-width-320 {
          width: 320px !important;
          margin-right: 24px;
        }
        .company-180 {
          width: 180px;
        }
        textarea {
          height: auto;
          padding: 28px 24px;
          resize: none;
          background: #f6f9fd !important;
        }
      }
      &.reserved-company {
        .reserved-text-w {
          input {
            min-width: 524px;
          }
          .upload-file {
            margin-left: 24px;
          }
        }
        .preferred-names {
          max-width: 524px;
        }
      }
      .language-preferred {
        &.inside-outside {
          .custom-radio-btn {
            input[type="radio"],
            input[type="checkbox"] {
              &:checked + label::after {
                left: 26px;
              }
            }
            label {
              width: 260px !important;
              margin-bottom: 20px;
            }
          }
        }
        &.new-company {
          label {
            padding: 0 48px;
            text-align: center;
          }
        }
        div {
          &:not(:last-child) {
            margin-right: 40px;
          }
          .form-control {
            max-width: 206px;
          }
        }
      }
      &.main-busines {
        .preferred-names {
          max-width: 540px;
          .position-relative {
            &::before {
              content: url(../src/assets/images/search-icon.svg);
              position: absolute;
              top: 55%;
              left: 5%;
              transform: translate(-50%, -50%);
            }
            input {
              padding: 0px 50px 0;
            }
          }
        }
        .business-category {
          &.active {
            color: $btn-bg-color;
            font-family: gotham-bold;
          }
          height: 60px;
          max-width: 140px;
          padding: 0 10px;
          @include bg-common($cyan-blue-second);
          margin-right: 10px;
          text-align: center;
          font-size: 14px;
          color: $place-holder-c;
          font-family: gotham-medium;
          line-height: 14px;
          margin-bottom: 10px;
        }
        .business-category-block-bg {
          @include bg-common($menu-circle-c);
          padding: 5px 12px;
          .business-category {
            margin-bottom: 0;
            margin: 5px 5px;
            font-family: gotham-bold;
          }
        }
        .accordion-style {
          @include bg-common($cyan-blue-second);
          padding: 12px 22px 12px 12px;
          .fade {
            display: none;
            transition: all 0.3s ease-in;
            &.show {
              display: block;
            }
          }
          button {
            background: transparent;
            color: $btn-bg-color;
            border: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-family: gotham-medium;
            position: relative;
            box-shadow: none;
            border-bottom: 2px solid #c15e35;
            border-radius: 0;
            padding: 0.375rem 0rem;
            margin: 0 23px 0 0;
            &::before {
              content: url(../src/assets/images/arrow-icon.png);
              position: absolute;
              right: 0;
              transform: rotate(180deg);
              transition: 0.3s ease-in;
            }
            &[aria-expanded="true"] {
              &::before {
                transform: rotate(0deg);
              }
            }
          }
          #example-fade-text {
            padding: 0 73px 0 20px;
            margin-top: 13px;
            .accordion-details-block {
              margin-bottom: 17px;
              .icon-right {
                margin-right: 18px;
              }
              p {
                font-family: gotham-light;
                color: $text-lable;
                margin: 0;
                border-bottom: 1px solid $place-holder-c;
                width: 100%;
                span {
                  font-family: gotham-medium;
                }
              }
            }
          }
        }
      }

      .timeline {
        position: relative;
        margin-top: -12px;
        li {
          &:last-child {
            &::after {
              content: none;
            }
          }
          list-style: none;
          float: left;
          width: 110px;
          position: relative;
          font-family: gotham-bold;
          text-transform: uppercase;
          color: $black-c;
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: block;
            text-align: center;
            line-height: 50px;
            background: $light-theme-orange;
            transition: all ease-in-out 0.3s;
            cursor: pointer;
            margin-top: 18px;
            margin-bottom: 11px;
            position: relative;
            z-index: 2;
            left: -6px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 12px;
            @include bg-common($cyan-blue-second);
            top: 25px;
            left: 0%;
            margin-left: 12px;
            z-index: 1;
            transition: all ease-in-out 0.3s;
          }
          &.active-tl {
            &::after {
              background: $theme-orange;
            }
          }
        }
      }

      .shareholders {
        .custom-radio-btn {
          min-width: 641px;
        }
      }
    }
    .form-control {
      background: $cyan-blue-second;
    }
  }
}

.amplify-tabs {
  display: none;
}

@media (max-width: 1420px) {
  .mt-20 {
    margin-top: 20px;
  }
}

@media (max-width: 1470px) {
  .base-timeline__summary-text {
    font-size: 16px !important;
    width: 160px !important;
    left: -32px !important;
  }
}

@media (max-width: 1382px) {
  .base-timeline {
    padding: 0 40px 0 30px;
  }
}

@media (max-width: 1199px) {
  .base-timeline {
    padding: 0 40px 0 0px;
    margin-bottom: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1199px) {
  .signup-screen {
    .text-block {
      h1 {
        font-size: 40px;
      }
      h4 {
        margin-bottom: 26px;
        line-height: 40px;
      }
      .main-timeline {
        position: relative;
        width: unset !important;
        &::before {
          content: "";
          width: 100%;
          border-top: 4px solid #000000;
          display: inline-block;
          position: absolute;
          top: 40%;
          z-index: 1;
          transform: rotate(90deg);
          left: -136px;
          @media (max-width: 410px) {
            left: -78px;
          }
        }
        .base-timeline {
          @media (max-width: 410px) {
            padding: 0;
          }
          &::before {
            content: none;
          }
          .base-timeline__item {
            margin-bottom: 10px;
            &::before {
              min-width: 91px;
              min-height: 91px;
            }
            width: 100%;
            display: flex;
            align-items: center;
            .base-timeline__summary-text {
              position: unset;
              min-width: 237px;
              @media (max-width: 410px) {
                max-width: 237px;
                min-width: unset;
              }
              text-align: left;
            }
          }
        }
      }
    }
    .form-block {
      min-width: 284px;
      margin: 0 16px;
      padding: 0;
    }
  }
  .layoutSidenav {
    .layoutSidenav_nav {
      width: 110px !important;
      .header-logo {
        max-width: 110px !important;
      }
      .header-sidebar {
        &:hover {
          width: 315px !important;
        }
        width: 110px !important;
        .sallis-dashboard-link {
          white-space: nowrap;
        }
        .sidebar-footer {
          flex-direction: column;
          span {
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
    }
    .layout-side-nav-content {
      margin-left: 110px !important;
      margin-right: 0;
      .content-block {
        margin-left: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .login-right {
    margin-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .signup-screen {
    .text-block {
      h1 {
        line-height: 50px;
      }
      h4 {
        line-height: 30px !important;
        font-size: 18px;
      }
    }
    > div:nth-child(1) {
      order: 2;
      padding: 24px 16px !important;
    }
    > div:nth-child(2) {
      order: 1;
    }
    flex-direction: column;
    .login-p {
      padding: 16px;
      h1 {
        margin-bottom: 20px !important;
        line-height: 40px;
      }
      h4 {
        line-height: 30px !important;
        font-size: 18px;
      }
    }
    .form-block {
      margin: 20px 16px;
    }
  }
}
.min-width-520 {
  min-width: 520px !important;
}
.mouse-hover {
  &:hover {
    cursor: pointer;
  }
}
.justify-content-center {
  justify-content: center;
}
.flex-direction-column {
  flex-direction: column;
}
.text-align-center {
  text-align: center;
}

.sallis-dashboard-link {
  &.beta-version {
    border: 2px solid;
    padding: 3px;
  }
}
.beta-version-button {
  .beta-button a {
    color: white;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    background-color: #ffa12b;
    display: block;
    position: relative;
    padding: 2px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0px 1px 0px #000;
    filter: dropshadow(color=#000, offx=0px, offy=1px);

    -webkit-box-shadow: inset 0 1px 0 #ffe5c4, 0 10px 0 #915100;
    -moz-box-shadow: inset 0 1px 0 #ffe5c4, 0 10px 0 #915100;
    box-shadow: inset 0 1px 0 #ffe5c4, 0 10px 0 #915100;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .beta-button a:active {
    top: 10px;
    background-color: #f78900;

    -webkit-box-shadow: inset 0 1px 0 #ffe5c4, inset 0 -3px 0 #915100;
    -moz-box-shadow: inset 0 1px 0 #ffe5c4, inset 0 -3pxpx 0 #915100;
    box-shadow: inset 0 1px 0 #ffe5c4, inset 0 -3px 0 #915100;
  }

  .beta-button:after {
    content: "";
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: #2b1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

.text-align-left {
  text-align: left;
}

.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.central-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-text-block-background{
  background: #223C5F !important;
}
:root {
  --toastify-color-success: #223B55;
  --toastify-icon-color-success: var(--toastify-color-success);
}
.toolTipCss {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: $th-new-black8-bg !important;
      color: $th-new-black3-main !important;
      border-radius: 5px;
    }
  }

}
.action-button-left-margin {
  padding-left: 10px;
  margin-left: 10px;

}
.react-bootstrap-table th .order > .dropdown > .caret {
    margin: 10px 0 10px 5px;
    color: #703c3c;
}
.react-bootstrap-table th .order > .dropup > .caret {
    margin: 10px 0;
    color: #cccccc;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}

 .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
 .btn-export-css {
   color: #fff;
   text-align: center;
   background-color: #1d3557;
   border-radius: 4px;
   font-family: Generalsans Variable, sans-serif;
   font-weight: 500;
   height: 40px !important;
   padding: 5px 10px !important;
   padding-right: 15px !important;
   text-decoration: none;
   transition-property: all;
   transition-timing-function: cubic-bezier(.075, .82, .165, 1);

   &:hover, &:focus {
     color: #fff;
     background-color: rgba(34, 59, 85, .8);
   }

   span {
     margin-left: 5px;
   }

 }
 .link-btn-css {
   color: #0d6efd ;
   text-align: center;
   font-family: Generalsans Variable, sans-serif;
   font-weight: 600;
   height: 40px !important;
   padding: 5px 10px !important;
   padding-right: 15px !important;
   &:hover, &:focus {
     color:  #0a58ca;
   }
   span {
     margin-left: 5px;
   }

 }
.spinLoading{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.4);
    z-index: 100;
}
.sortable {
  &:hover {
    cursor: pointer;
  }
}
.react-bootstrap-table{
  min-height: 200px!important;
}
.adminLayoutFlex{
    display: flex;
    flex-direction: column;
    width: 248px;
}